import { QuicklinkModule } from 'ngx-quicklink';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { SearchAddressFullFormComponent } from './components/search-address-full-form/search-address-full-form.component';
import { NoAccessSoftwareRentContractComponent } from './components/no-access-software-rent-contract/no-access-software-rent-contract.component';
import { AddressCardComponent } from '@shared/components/address-card/address-card.component';
import { AddressTreeComponent } from '@shared/components/address-card/components/address-tree/address-tree.component';
import { AddressElementCardComponent } from '@shared/components/address-card/components/address-element-card/address-element-card.component';
import { AddressObjectCardComponent } from '@shared/components/address-card/components/address-object-card/address-object-card.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ImportAddressComponent } from './components/address-card/components/import-address/import-address.component';
import { AuthorizationComponent } from '@shared/components/authorization/authorization.component';
import { AddGuideModalComponent } from '@shared/components/add-guide-modal/add-guide-modal.component';
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.extend(dayjsTimezone);
dayjs.locale(dayjsRu);
import { ProcessProgressIndicatorModalComponent } from './components/process-progress-indicator-modal/process-progress-indicator-modal.component';
import { AccordionItemModule } from 'web-frontend-component-library/components/accordion-item';
import { ActionMenuModule } from 'web-frontend-component-library/components/action-menu';
import { BasePageWithChildsModule } from 'web-frontend-component-library/components/base-page-with-childs';
import { BreadcrumbsModule } from 'web-frontend-component-library/components/breadcrumbs';
import { ButtonModule } from 'web-frontend-component-library/components/button';
import { CalendarModule } from 'web-frontend-component-library/components/calendar';
import { CheckboxModule } from 'web-frontend-component-library/components/checkbox';
import { CheckboxListModule } from 'web-frontend-component-library//components/checkbox-list';
import { ConfirmModalModule } from 'web-frontend-component-library/components/confirm-modal';
import { DownloadProgressModule } from 'web-frontend-component-library/components/download-progress';
import { EventLogCardModule } from 'web-frontend-component-library/components/event-log-card';
import { FilesListModule } from 'web-frontend-component-library/components/files-list';
import { InputModule } from 'web-frontend-component-library/components/input';
import { LockEditableModule } from 'web-frontend-component-library/components/lock-editable';
import { LoaderModule } from 'web-frontend-component-library/components/loader';
import { MenuModule } from 'web-frontend-component-library/components/menu';
import { MultiSelectModule } from 'web-frontend-component-library/components/multi-select';
import { NoSelectStubModule } from 'web-frontend-component-library/components/no-select-stub';
import { NoAccessStubModule } from 'web-frontend-component-library/components/no-access-stub';
import { NsiDictionarySelectListModule } from 'web-frontend-component-library/components/nsi-dictionary-select-list';
import { PasswordInputModule } from 'web-frontend-component-library/components/password-input';
import { RadioboxListModule } from 'web-frontend-component-library/components/radiobox-list';
import { RoleAccessTreeModule } from 'web-frontend-component-library/components/role-access-tree';
import { RoundProgressBarModule } from 'web-frontend-component-library/components/round-progress-bar';
import { SearchFieldModule } from 'web-frontend-component-library/components/search-field';
import { SelectSearchListModule } from 'web-frontend-component-library/components/select-search-list';
import { SimpleSelectModule } from 'web-frontend-component-library/components/simple-select';
import { SwitchModule } from 'web-frontend-component-library/components/switch';
import { TabsModule } from 'web-frontend-component-library/components/tabs';
import { TextareaModule } from 'web-frontend-component-library/components/textarea';
import { ToastrModule } from 'web-frontend-component-library/components/toastr';
import { UniversalTreeModule } from 'web-frontend-component-library/components/universal-tree';
import { UniversalTreeItemModule } from 'web-frontend-component-library/components/universal-tree-item';
import { DisableControlModule } from 'web-frontend-component-library/directives/disable-control';
import { FormatDatePipeModule } from 'web-frontend-component-library/pipes/format-date';
import { IsVisiblePipeModule } from 'web-frontend-component-library/pipes/is-visible';
import { FixedToModule } from 'web-frontend-component-library/directives/fixed-to';
import { SelectFieldModule } from 'web-frontend-component-library/components/select-fiend';
import { GroupTypeSettingsModule } from './components/group-type-settings/group-type-settings.module';
import { LoadingStringModule } from 'web-frontend-component-library/components/loading-string';
import { ProcessProgressSuccessModalComponent } from './components/process-progress-success-modal/process-progress-success-modal.component';
import {ExpireDateTextComponent} from "@shared/components/expire-date-text/expire-date-text.component";
import {NumberAbsModule} from "@shared/pipes/number-abs/number-abs.module";
import {ExpiryDateIconsHelperService} from "@shared/services/expiry-date-icons-helper.service";
import { PopupContainerModule } from 'web-frontend-component-library/components/popup-container';
import { FocusTrapModule } from 'web-frontend-component-library/directives/focus-trap';
import {
  NetworkSectionCardSharedComponent
} from '@shared/components/network-section-card/network-section-card-shared.component';
import { DictionaryModule } from 'web-frontend-component-library/components/dictionary';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    QuicklinkModule,
    // from lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    EventLogCardModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MenuModule,
    MultiSelectModule,
    NoSelectStubModule,
    NoAccessStubModule,
    NsiDictionarySelectListModule,
    PasswordInputModule,
    RadioboxListModule,
    RoleAccessTreeModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    FixedToModule,
    SelectFieldModule,
    GroupTypeSettingsModule,
    NumberAbsModule,
    PopupContainerModule,
    FocusTrapModule,
    DictionaryModule,
  ],
  declarations: [
    HeaderComponent,
    SearchAddressFullFormComponent,
    NoAccessSoftwareRentContractComponent,
    AddressCardComponent,
    AddressTreeComponent,
    AddressElementCardComponent,
    AddressObjectCardComponent,
    AddressSearchComponent,
    ImportAddressComponent,
    AuthorizationComponent,
    AddGuideModalComponent,
    ProcessProgressIndicatorModalComponent,
    ProcessProgressSuccessModalComponent,
    ExpireDateTextComponent,
    NetworkSectionCardSharedComponent
  ],
  exports: [
    // modules
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgbModule,
    QuicklinkModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    ContextMenuModule,
    // from lib
    AccordionItemModule,
    ActionMenuModule,
    BasePageWithChildsModule,
    BreadcrumbsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CheckboxListModule,
    ConfirmModalModule,
    DownloadProgressModule,
    EventLogCardModule,
    FilesListModule,
    InputModule,
    LoaderModule,
    LockEditableModule,
    MenuModule,
    MultiSelectModule,
    NoSelectStubModule,
    NoAccessStubModule,
    NsiDictionarySelectListModule,
    PasswordInputModule,
    RadioboxListModule,
    RoleAccessTreeModule,
    RoundProgressBarModule,
    SearchFieldModule,
    SelectSearchListModule,
    SimpleSelectModule,
    SwitchModule,
    TabsModule,
    TextareaModule,
    ToastrModule,
    UniversalTreeModule,
    UniversalTreeItemModule,
    DisableControlModule,
    FormatDatePipeModule,
    IsVisiblePipeModule,
    FixedToModule,
    SelectFieldModule,
    LoadingStringModule,
    PopupContainerModule,
    FocusTrapModule,
    // components
    HeaderComponent,
    AddressCardComponent,
    AddressTreeComponent,
    AddressElementCardComponent,
    AddressObjectCardComponent,
    AuthorizationComponent,
    AddGuideModalComponent,
    ProcessProgressIndicatorModalComponent,
    GroupTypeSettingsModule,
    ProcessProgressSuccessModalComponent,
    NumberAbsModule,
    ExpireDateTextComponent,
    NetworkSectionCardSharedComponent,
    DictionaryModule,
  ],
  entryComponents: [
    AddressCardComponent,
    AddGuideModalComponent,
    ProcessProgressIndicatorModalComponent,
    ProcessProgressSuccessModalComponent
  ],
  providers: [
    ExpiryDateIconsHelperService
  ]
})
export class SharedModule { }
